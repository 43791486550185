.cs-patient-surgical-history-page {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 20px;
  border-radius: 2px;
}

.cs-patient-surgical-history-section {
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-patient-surgical-history-title-text p {
  font-size: 24px;
  margin-bottom: 0;
  padding-bottom: .5em;
  margin-top: .5em;
  padding-top: 0;
}

.cs-patient-surgical-history-title {
  border-bottom: 1px solid #e0e0e0;
}

.cs-patient-surgical-history-footer {
  margin-right: 0!important;
  margin-left: 0!important;
  border-top: 1px solid #e0e0e0;
  min-height: 3.15em;
}
