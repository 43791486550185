.cs-login-reset-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-login-reset-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-login-reset-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-login-reset-close-button {
    color: #0B7DBB!important;
}

.cs-login-reset-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-login-reset-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-login-reset-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-login-reset-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-login-reset-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}
