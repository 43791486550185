.cs-patient-vitals-range-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-patient-vitals-range-button {
    color: #0B7DBB!important;
}

.cs-patient-vitals-range-li {
    padding: .5em!important;
}

.cs-patient-vitals-range-icon {
    height: 1.5em!important;
    color: #12B1B9;
    margin-right: 1em!important;
    min-width: 1.75em!important;
}
