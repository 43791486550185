.cs-calendar-main {
    padding-top: 2.5em;
    padding-bottom: 2em;
}

.cs-calendar-main-xs {
    padding-top: 2em;
    padding-bottom: 2em;
}

.cs-calendar-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-calendar-container-xs {
    padding-left: 0;
    padding-top: .5em;
}

.cs-calendar-resource-avatar {
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: .75em!important;
    margin-right: .5em!important;
}

.cs-calendar-resource-avatar-xs {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: .75em!important;
    margin-right: .5em!important;
}

.cs-calendar-resource-text {
    margin-top: .75em!important;
}

.cs-calendar-event-secondary {
    font-size: 1em!important;
    font-weight: 400!important;
}

.cs-calendar-event-month {
    padding-left: .5em!important;
}

.cs-calendar-event-agenda-resource {
    padding-top: .25em!important;
}

.cs-calendar-event-secondary-first {
    font-size: 1em!important;
    font-weight: 400!important;
    padding-right: .5em!important;
}

.cs-calendar-event-icon-info {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-left: 1em!important;
    margin-right: .5em!important;
}

.cs-calendar-event-icon-pipe-xs {
    height: 1em!important;
    color: #0B7DBB!important;
    margin-left: .5em!important;
    margin-right: .5em!important;
}

.cs-calendar-event-icon-info-xs {
    height: 1em!important;
    color: #0B7DBB!important;
    margin-right: .5em!important;
}

.cs-calendar-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-calendar-title-input .mbsc-textfield {
    color: #0FBEDB!important;
    font-size: 1.5em!important;
    margin: .25em 0 0 -1em !important;
    background: #efeff4!important;
    cursor: pointer;
}

.cs-calendar-title-input .mbsc-textfield-wrapper-box {
    margin: 0!important;
}

.cs-calendar-menu {
    background: white;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-calendar-menu-xs {
    background: white;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-calendar-menu-agenda {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-calendar-menu-agenda-xs {
    background: white;
}

.cs-calendar-select {
    padding: 0!important;
}

.cs-calendar-buttons {
    padding-right: 1.5em!important;
}

.cs-calendar-picker input {
    border-width: 0!important;
    color: #3f97f6!important;
    font-weight: bold!important;
    font-size: 1em!important;
    text-align: end!important;
}

.cs-calendar-select .mbsc-form-control-wrapper:before {
    border-bottom: 0 solid white!important;
    border-top: 0 solid #efeff4!important;
}

.cs-calendar-select .mbsc-form-control-wrapper:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-calendar-button-text {
    color: #0B7DBB!important;
}

.cs-calendar-icon {
    height: 1.5em!important;
    margin-right: .75em!important;
    color: #0B7DBB;
}

.cs-calendar-icon-xs {
    height: 1.5em!important;
    color: #0B7DBB;
}

.cs-calendar-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-calendar-section-xs {
    background: white;
}

.cs-calendar-col {
    padding: 0!important;
}

.cs-calendar-controls-button .mbsc-calendar-button {
    color: #0B7DBB!important;
}

.cs-calendar-controls-picker {
    margin-right: 2em!important;
    margin-left: .5em!important;
}

.cs-calendar-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: right;
    align-items: center;
}
