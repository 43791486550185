.cs-microapp-patient-visit-instructions-main {
  padding-top: 6em;
}

.cs-microapp-patient-visit-instructions-page {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 20px;
  border-radius: 2px;
}

.cs-microapp-patient-visit-instructions-title-text p {
  font-size: 24px;
  margin-bottom: 0;
  padding-bottom: .5em;
  margin-top: .5em;
  padding-top: 0;
}

.cs-microapp-patient-visit-instructions-button {
  padding: 0!important;
  margin-right: 0!important;
  margin-top: .75em!important;
  margin-bottom: 0!important;
}

.cs-microapp-patient-visit-instructions-title {
  border-bottom: 1px solid #e0e0e0;
}

.cs-microapp-patient-visit-instructions-section {
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-microapp-patient-visit-instructions-loading {
  margin-right: 1em;
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 3em;
}
