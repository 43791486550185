.cs-patient-past-visit-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-patient-past-visit-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-patient-past-visit-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-patient-past-visit-close-button {
    color: #0B7DBB!important;
}

.cs-patient-past-visit-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-patient-past-visit-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-past-visit-nav {
    color: #0B7DBB!important;
}

.cs-patient-past-visit-section {
    background: white!important;
}

.cs-patient-past-visit-list-triage {
    margin-top: .5em;
    margin-left: 0;
    margin-right: 0;
}

.cs-patient-past-visit-list-file {
    margin-left: 0;
    margin-right: 0;
}

.cs-patient-past-visit-list-section {
    margin-left: 1em;
    padding-top: .25em!important;
}

.cs-patient-past-visit-item-txt-triage {
    padding-left: 1em!important;
    margin-top: .5em!important;
}

.cs-patient-past-visit-item-txt-file {
    padding-left: 1em!important;
    margin-top: 0;
}

.cs-patient-past-visit-list-icon {
    height: 2.5em!important;
    margin-right: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .25em!important;
    color: #376e9f!important;
}
