.cs-patient-vitals-page {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 20px;
  border-radius: 2px;
}

.cs-patient-vitals-section {
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-patient-vitals-title-text p {
  font-size: 24px;
  margin-bottom: 0;
  padding-bottom: .5em;
  margin-top: .5em;
  padding-top: 0;
}

.cs-patient-vitals-title {
  border-bottom: 1px solid #e0e0e0;
}

.cs-patient-vitals-footer {
  margin-right: 0!important;
  margin-left: 0!important;
  border-top: 1px solid #e0e0e0;
  min-height: 3.15em;
}

.cs-patient-vitals-button {
  padding: 0!important;
  margin-right: 1em!important;
  margin-top: .75em!important;
  margin-bottom: 0!important;
}

.cs-patient-vitals-icon {
  height: 1.5em!important;
  color: #0B7DBB;
  margin-left: .25em!important;
  min-width: 1.75em!important;
}

.cs-patient-vitals-chart {
  background: white;
  margin-right: 0;
  margin-left: 0;
}

.cs-patient-vitals-loading {
  padding: 2em;
}

.cs-patient-vitals-grid {
  padding-top: 1em;
  padding-bottom: .5em;
  margin-left: 0;
  padding-left: 1em;
  margin-right: 0;
}

.cs-patient-vitals-grid-header {
  background: rgba(0,123,255,0.1)!important
}

.cs-patient-vitals-grid-item {
  padding: 0!important;
}

.cs-patient-vitals-grid-item-systolic {
  color: rgb(75, 192, 192)!important;
}

.cs-patient-vitals-grid-item-diastolic {
  color: rgb(153, 102, 255)!important;
}

.cs-patient-vitals-grid-item-data {
  color: rgb(53, 162, 235)!important;
}

.cs-patient-vitals-grid-item-end {
  padding: 0 0 0 .75em !important;
}

.cs-patient-vitals-picker input {
  border-width: 0!important;
  color: #3f97f6!important;
  font-weight: bold!important;
  font-size: 1em!important;
  text-align: end!important;
}
