.mbsc-ios.mbsc-input .mbsc-label {
    color: #191F3B!important;
}

.mbsc-ios.mbsc-label, .mbsc-ios.mbsc-textfield {
    color: #191F3B!important;
}

.mbsc-ios .mbsc-fr-btn {
    color: #0B7DBB!important;
}

.mbsc-popup-header {
    color: #0B7DBB!important;
}

.ck-editor__editable_inline {
    min-height: 15em!important;
    margin-left: .6em!important;
}

.ck.ck-block-toolbar-button {
    transform: translateX(calc(var(--ck-spacing-large)*-0))!important;
}

.mbsc-timeline-header-active:after {
    background: #0B7DBB!important;
}

.mbsc-calendar-header {
    background: white!important;
}
