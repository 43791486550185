.cs-login-main {
  padding-top: 6em;
}

.cs-login-card {
  background: white;
}

.cs-login-card-logo {
  height: 7em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 25px;
}

.cs-login-header-mobile {
  padding-bottom: 0.1em!important;
}

.cs-login-card-title {
  text-align: center;
  padding-bottom: 1em;
}

.cs-login-nav {
  background: #0FBEDB!important;
}

.cs-login-button {
  background: #0B7DBB!important;
  color: white!important;
}

.cs-login-button-lang {
  margin: 0!important;
  color: #12B1B9!important;
}

.cs-login-icon-secondary {
  height: 1.5em!important;
  color: #12B1B9;
  padding-right: 0.5em;
}

.cs-login-footer-section {
  padding-top: 1.5em!important;
}

.cs-login-footer {
  padding: 0!important;
}

.cs-login-inputs-right {
  padding-left: 0!important;
}

.cs-login-inputs-left {
  padding-right: 0!important;
}

.cs-login-inputs-right-input {
  margin-left: 0!important;
}
