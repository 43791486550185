.cs-patient-visit-page {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 20px;
  border-radius: 2px;
}

.cs-patient-visit-page-xs {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 0!important;
  border-radius: 2px;
}

.cs-patient-visit-section {
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-patient-visit-list {
  margin-left: 0;
  margin-right: 0;
}

.cs-patient-visit-list-button {
  margin-right: 0;
  margin-left: 0;
}

.cs-patient-visit-list-icon {
  height: 2.5em!important;
  margin-right: 0!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
  margin-left: .25em!important;
  color: #376e9f!important;
}

.cs-patient-visit-list-section {
  margin-left: 1em;
  padding-top: .25em!important;
}

.cs-patient-visit-item-txt {
  padding-left: 1em!important;
  margin-top: 0;
}

.cs-patient-visit-textarea .mbsc-textarea {
  height: 6em!important;
}

.cs-patient-visit-textarea {
  padding: 0!important;
}

.cs-patient-visit-textarea-input:after {
  border-width: 0!important;
}

.cs-patient-visit-title-text p {
  font-size: 24px;
  margin-bottom: 0;
  padding-bottom: .5em;
  margin-top: .5em;
  padding-top: 0;
}

.cs-patient-visit-title {
  border-bottom: 1px solid #e0e0e0;
}

.cs-patient-visit-footer {
  margin-right: 0!important;
  margin-left: 0!important;
  border-top: 1px solid #e0e0e0;
  min-height: 3.15em;
}

.cs-patient-visit-loading {
  margin-right: 1em;
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 3em;
}

.cs-patient-visit-button {
  padding: 0!important;
  margin-right: 0!important;
  margin-top: .75em!important;
  margin-bottom: 0!important;
  color: #0B7DBB!important;
}

.cs-patient-visit-button-multi {
  padding: 0!important;
  margin-right: 1em!important;
  margin-top: .75em!important;
  margin-bottom: 0!important;
  color: #0B7DBB!important;
}

.cs-patient-visit-icon {
  height: 1.75em!important;
  margin-top: -.15em!important;
  margin-bottom: -.15em!important;
  margin-left: 0!important;
  padding-top: .5em!important;
  color: #0B7DBB!important;
}
