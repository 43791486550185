.cs-queue-visit-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-queue-visit-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-queue-visit-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-queue-visit-list {
    margin-top: .5em;
    margin-left: 0;
    margin-right: 0;
}

.cs-queue-visit-list-icon-mobile {
    height: 2.5em!important;
    margin-right: .5em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .25em!important;
    color: #376e9f!important;
}

.cs-queue-visit-list-section {
    margin-left: 1em;
    padding-top: .25em!important;
}

.cs-queue-visit-item-txt {
    padding-left: 1em!important;
    margin-top: 0;
}

.cs-queue-visit-nav {
    color: #0B7DBB!important;
}

.cs-queue-visit-loading {
    margin-right: 1em;
    margin-left: 1em;
    padding-top: 8em;
    padding-bottom: 8em;
}

.cs-queue-visit-qr {
    padding-top: 4em;
    padding-bottom: 4em;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.cs-queue-visit-textarea-reason .mbsc-textarea {
    height: 4em!important;
}

.cs-queue-visit-textarea-notes .mbsc-textarea {
    height: 9em!important;
}

.cs-queue-visit-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-queue-visit-delete-button {
    margin: 0!important;
    color: #0B7DBB!important;
}

.cs-queue-visit-close-button {
    color: #0B7DBB!important;
}

.cs-queue-visit-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}

.cs-queue-visit-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-queue-visit-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-queue-visit-delete-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-queue-visit-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}
