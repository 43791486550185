.cs-patient-visit-file-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-patient-visit-file-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-patient-visit-file-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-patient-visit-file-delete-button {
    margin: 0!important;
    color: #0B7DBB!important;
}

.cs-patient-visit-file-close-button {
    color: #0B7DBB!important;
}

.cs-patient-visit-file-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-patient-visit-file-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-visit-file-delete-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-visit-file-textarea .mbsc-textarea {
    height: 3em!important;
}

.cs-patient-visit-file-loading {
    margin-right: 1em;
    margin-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.cs-patient-visit-file-image {
    margin: 0!important;
}
