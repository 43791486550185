.cs-microapp-queue-wait-main {
  padding-top: 6em;
}

.cs-microapp-queue-wait-main-small {
  padding-top: 4em;
}

.cs-microapp-queue-wait-page {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 0!important;
  border-radius: 2px;
}

.cs-microapp-queue-wait-section {
  padding-top: 1.5em!important;
  padding-bottom: 1.5em!important;
}

.cs-microapp-queue-wait-button {
  padding-top: .5em !important;
  padding-bottom: .5em !important;
  background: #12B1B9!important;
}

.cs-microapp-queue-wait-button-bottom {
  padding-top: .25em !important;
  padding-bottom: .25em !important;
  margin: 0!important;
  background: #12B1B9!important;
  border-radius: 0!important;
}

.cs-microapp-queue-wait-nav-bottom .mbsc-ms-item {
  padding: 0!important;
}

.cs-microapp-queue-wait-helper {
  margin-right: 1em;
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.cs-microapp-queue-wait-loading {
  margin-right: 1em;
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 3em;
}

.cs-microapp-queue-wait-grid {
  padding-left: 0!important;
  padding-right: 0!important;
}

.cs-microapp-queue-wait-inputs-left {
  padding-right: 0!important;
}

.cs-microapp-queue-wait-inputs-right {
  padding-left: 0!important;
}

.cs-microapp-queue-wait-inputs-right-input {
  margin-left: 0!important;
  height: 4em!important;
}

.cs-microapp-queue-wait-input {
  height: 4em!important;
}

.cs-microapp-queue-wait-input-triage {
  height: 2.70em!important;
}

.cs-microapp-queue-wait-textarea .mbsc-textarea {
  height: 9em!important;
}
