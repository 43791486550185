.cs-dashboard-header-profile-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-header-profile-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-profile-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-profile-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-profile-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-profile-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-header-profile-avatar {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em!important;
}

.cs-dashboard-header-profile-nav {
    color: #0B7DBB!important;
}

.cs-dashboard-header-profile-input:before {
    border-top: 1px solid white!important;
}

.cs-dashboard-header-profile-link a {
    color: #0FBEDB!important;
}

.cs-dashboard-header-profile-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-header-profile-grid {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-profile-mobile-row {
    margin-bottom: -1.5em!important;
    margin-top: -1em!important;
}

.cs-dashboard-header-profile-inputs-left {
    padding-right: 0!important;
}

.cs-dashboard-header-profile-inputs-right {
    padding-left: 0!important;
}

.cs-dashboard-header-profile-inputs-right-input {
    margin-left: 0!important;
}

.cs-dashboard-header-profile-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
}

.cs-dashboard-header-profile-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-dashboard-header-profile-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}
