.cs-register-title {
  color: #4EAAE2;
  text-align: center;
}

.cs-register-link a {
  color: white !important;
}

.cs-register-login {
  color: white !important;
}

.cs-register-form-privacy {
  max-height: 400px;
  overflow-y: auto;
  background-color: #F1F1F1FF;
}

.cs-register-button {
  background: #00BAEC !important;
}

.cs-register-micons {
  background-image: url("../images/logo_logo.png");
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
