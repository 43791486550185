.cs-shared-new-menu-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-shared-new-menu-button {
    color: #0B7DBB!important;
}

.cs-shared-new-menu-li {
    padding: .5em!important;
}

.cs-shared-new-menu-icon {
    height: 1.5em!important;
    color: #12B1B9;
    margin-right: 1em!important;
    min-width: 1.75em!important;
}
