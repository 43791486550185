.cs-queue-wait-section {
  background: white;
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-queue-wait-menu {
  background: white;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
  margin-right: 0!important;
  margin-left: 0!important;
}

.cs-queue-wait-list {
  margin-top: .5em;
  margin-left: 0;
  margin-right: 0;
}

.cs-queue-wait-list-button {
  margin-right: .5em;
  margin-left: 0;
}

.cs-queue-wait-list-icon-mobile {
  height: 2.5em!important;
  margin-right: .5em!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
  margin-left: .25em!important;
  color: #376e9f!important;
}

.cs-queue-wait-list-icon-whatsapp {
  height: 2.5em!important;
  margin-right: 0!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
  margin-left: .25em!important;
  color: #376e9f!important;
}

.cs-queue-wait-list-icon-email {
  height: 2.5em!important;
  margin-right: 0!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
  margin-left: .25em!important;
  color: #376e9f!important;
}

.cs-queue-wait-list-section {
  margin-left: 1em;
  padding-top: .25em!important;
}

.cs-queue-wait-item-txt {
  padding-left: 1em!important;
  margin-top: 0;
}

.cs-queue-wait-icon {
  height: 1.5em!important;
  margin-right: .75em!important;
  color: #0B7DBB;
}

.cs-queue-wait-button-text {
  color: #0B7DBB!important;
}

.cs-queue-wait-icon-right {
  height: 2em!important;
  margin-right: .3em!important;
  margin-left: .5em!important;
  color: #0B7DBB;
}

.cs-queue-wait-icon-info {
  height: 1em!important;
  color: #0B7DBB!important;
  margin-left: .5em!important;
  margin-top: .25em!important;
}

.cs-queue-wait-icon-pipe-xs {
  height: 1em!important;
  color: #0B7DBB!important;
  margin-left: .25em!important;
  margin-right: .25em!important;
}
