.cs-microapp-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
  padding: 0!important;
}

.cs-microapp-header-button-text {
  color: #0B7DBB!important;
}

.cs-microapp-header-button-text-logo {
  color: #0B7DBB!important;
  margin-left: 0!important;
  padding-left: 0!important;
}

.cs-microapp-header-logo {
  margin-left: 2em;
  width: 3em;
  height: 2.5em;
  margin-top: .3em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-microapp-header-menu-end {
  margin-right: 0!important;
}

.cs-microapp-header-button-icon {
  padding-top: .5em!important;
  height: 1.5em!important;
  color: #0B7DBB;
}

.cs-microapp-header-menu-text {
  margin-top: .25em!important;
  margin-bottom: -.25em!important;
  padding-left: .75em!important;
}

.cs-microapp-header-button {
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-left: 0!important;
  padding-right: 1em!important;
}

.cs-microapp-header-button-menu {
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-left: 0!important;
  padding-right: 2em!important;
}

.cs-microapp-header-button-menu-xs {
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-left: 0!important;
  padding-right: 1em!important;
}
