.cs-patient-allergy-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-patient-allergy-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-patient-allergy-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-patient-allergy-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-allergy-delete-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-allergy-delete-button {
    margin: 0!important;
    color: #0B7DBB!important;
}

.cs-patient-allergy-close-button {
    color: #0B7DBB!important;
}

.cs-patient-allergy-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-patient-allergy-grid {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-patient-allergy-inputs-left {
    padding-right: 0!important;
}

.cs-patient-allergy-inputs-right {
    padding-left: 0!important;
}

.cs-patient-allergy-inputs-right-input {
    margin-left: 0!important;
}

.cs-patient-allergy-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-patient-allergy-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}
