.cs-calendar-select-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-calendar-select-icon {
    height: 1.5em!important;
    color: #12B1B9;
    margin-right: 1em!important;
    min-width: 1.75em!important;
}
