.cs-dashboard-header-menu-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-header-menu-li {
    padding: .5em!important;
}

.cs-dashboard-header-menu-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .25em;
    margin-left: 0;
    margin-right: 1em;
}

.cs-dashboard-header-menu-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-menu-icon {
    height: 1.5em!important;
    color: #12B1B9;
    margin-right: 1em!important;
    min-width: 1.75em!important;
}

.cs-dashboard-header-menu-icon-danger {
    height: 1.5em!important;
    color: #f5504e;
    margin-right: 1em!important;
    min-width: 1.75em!important;
}
