.cs-patient-main {
    padding-top: 2.5em;
    padding-bottom: 2em;
}

.cs-patient-main-xs {
    padding-top: 2em;
    padding-bottom: 2em;
}

.cs-patient-left {
    padding-right: 0!important;
}

.cs-patient-right {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
}

.cs-patient-right-xs {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
    padding: 0!important;
}

.cs-patient-nav {
    color: #0B7DBB!important;
}

.cs-patient-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-patient-container-xs {
    padding-left: 0;
    padding-top: .5em;
}

.cs-patient-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-patient-title-input .mbsc-textfield {
    color: #0FBEDB!important;
    font-size: 1.5em!important;
    margin: .25em 0 0 -1em !important;
    background: #efeff4!important;
    cursor: pointer;
}

.cs-patient-title-input .mbsc-textfield-wrapper-box {
    margin: 0!important;
}
