.cs-patient-vitals-metric-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-patient-vitals-metric-button {
    color: #0B7DBB!important;
}

.cs-patient-vitals-metric-li {
    padding: .5em!important;
}

.cs-patient-vitals-metric-icon {
    height: 1.5em!important;
    color: #0B7DBB;
    margin-left: 1em!important;
    min-width: 1.75em!important;
}
