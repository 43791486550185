.cs-patient-visit-triage-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-patient-visit-triage-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-patient-visit-triage-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-patient-visit-triage-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-patient-visit-triage-close-button {
    color: #0B7DBB!important;
}

.cs-patient-visit-triage-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-patient-visit-triage-list {
    margin-top: .5em;
    margin-left: 0;
    margin-right: 0;
}

.cs-patient-visit-triage-list-section {
    margin-left: 1em;
    padding-top: .25em!important;
}

.cs-patient-visit-triage-item-txt {
    padding-left: 1em!important;
    margin-top: .5em!important;
}
